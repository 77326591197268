<template>
  <v-container class="pa-0">
    <v-card class="mx-auto elevation-0 mb-5" outlined max-width="1540">
      <v-data-table
        :headers="headers"
        :items="allPlans"
        :search="search"
        :items-per-page="itemsPerPage"
        :footer-props="{ 'items-per-page-options': itemsOptions }"
        :sort-by="['name']"
        :sort-desc="[false]"
        item-key="key"
        :loading="loadingTable"
        loading-text="Atualizando lista de planos... Por favor, aguarde"
        :height="tableHeight"
      >
        <template v-slot:top>
          <v-row
            no-gutters
            class="d-flex align-center px-5 py-3"
            style="border-bottom: 1px solid rgba(0, 0, 0, 0.12)"
          >
            <h2 class="font-weight-regular">Gerenciar planos</h2>
            <v-spacer></v-spacer>
            <v-btn
              :block="$vuetify.breakpoint.smAndDown"
              large
              color="accent"
              outlined
              :class="`px-4 text-none elevation-0 text-body-1 font-weight-bold ${
                $vuetify.breakpoint.smAndDown ? 'my-2' : 'mr-4'
              }`"
              @click="openConfigModal"
            >
              <v-icon v-text="'mdi-cog'" left />
              Configurar
            </v-btn>
            <v-btn
              large
              :block="$vuetify.breakpoint.smAndDown"
              color="accent"
              dark
              :class="`px-4 text-none elevation-0 text-body-1 font-weight-bold ${
                $vuetify.breakpoint.smAndDown ? 'mb-2' : ''
              }`"
              @click.capture="createNewPlanOrService()"
            >
              <v-icon v-text="'mdi-plus'" left />
              Novo plano
            </v-btn>
          </v-row>

          <v-row class="ma-0 pa-0">
            <v-col class="pa-0 d-flex align-center">
              <v-text-field
                v-model="search"
                prepend-inner-icon="mdi-magnify"
                label="Pesquisar por plano"
                single-line
                hide-details
                style="max-width: 300px"
                class="py-3 px-4 ma-0"
                color="grey"
                :loading="loadingTable"
                outlined
                dense
                clearable
              ></v-text-field>

              <v-menu offset-y :close-on-content-click="false">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="accent"
                    class="text-subtitle-1 text-none font-weight-medium"
                    :loading="loadingTable"
                    v-bind="attrs"
                    v-on="on"
                    text
                  >
                    <v-icon
                      v-text="'mdi-filter-variant'"
                      size="20"
                      class="mr-2"
                    />
                    Adicionar filtro
                  </v-btn>
                </template>
                <ButtonMenu
                  :options="filterOptions"
                  action="selectFilter"
                  @selectFilter="selectFilter"
                />
              </v-menu>
              <div class="d-flex flex-wrap">
                <v-chip
                  v-for="filter in allFilters"
                  :key="filter.name"
                  outlined
                  small
                  class="mr-1 my-1 grey--text text--darken-1 font-weight-medium"
                >
                  {{ filter.name }}
                  <v-btn
                    x-small
                    icon
                    class="ml-2"
                    @click="removeFilter(filter)"
                  >
                    <v-icon v-text="'mdi-window-close'" size="16" />
                  </v-btn>
                </v-chip>
              </div>

              <v-btn
                v-if="hasFiltersSelected"
                icon
                class="ml-auto mr-2"
                title="Limpar filtros"
                @click="clearAllFilters()"
              >
                <v-icon v-text="'mdi-window-close'" />
              </v-btn>
            </v-col>
          </v-row>

          <v-divider />
        </template>

        <template v-slot:[`item.name`]="{ item }">
          <span
            class="blue--text text--darken-2"
            @click="selectPlan(item)"
            style="cursor: pointer"
            >{{ item.name }}
          </span>
        </template>

        <template v-slot:[`item.products`]="{ item }">
          <div class="d-flex align-left">
            <div
              v-for="product in item.products"
              :key="product.name"
              class="mr-2"
            >
              <Logo
                v-if="product"
                :product="product.name"
                height="30"
                width="30"
                no-margins
                :title="productNames[product.name]"
                products
              />
            </div>
          </div>
        </template>

        <template v-slot:[`item.is_service`]="{ item }">
          <span>{{ item.is_service ? "Único" : "Mensal, Anual" }}</span>
        </template>

        <template v-slot:[`item.price`]="{ item }">
          {{ formatMoney(item.price) }}
        </template>

        <template v-slot:[`item.is_active`]="{ item }">
          <v-progress-circular
            indeterminate
            color="grey"
            v-if="loadingTable"
            width="3"
            size="20"
          ></v-progress-circular>

          <div v-else>
            <v-chip
              :color="item.is_active ? 'success' : 'secondary lighten-3'"
              small
              class="font-weight-medium"
            >
              {{ item.is_active ? "Ativo" : "Desativado" }}
              <TooltipIcon
                icon="mdi-pencil"
                color="white"
                dark
                :label="
                  item.is_active
                    ? 'Alterar para Desativado'
                    : 'Alterar para Ativo'
                "
                button
                class="ml-2"
                size="18"
                xs
                @action="activeOrDeactivePlan(item)"
              />
            </v-chip>
          </div>
        </template>

        <template v-slot:[`item.is_visible`]="{ item }">
          <v-progress-circular
            indeterminate
            color="grey"
            v-if="loadingTable"
            width="3"
            size="20"
          ></v-progress-circular>
          <div v-else>
            <span
              v-if="item.is_service || item.only_conecta_hires"
              class="font-weight-medium"
            >
              Apenas conecta nuvem
            </span>
            <span v-else-if="item.is_visible" class="font-weight-medium">
              Todos
            </span>
            <span v-else class="font-italic"> Apenas revendedores </span>
          </div>
        </template>

        <template v-slot:[`item.sku`]="{ item }">
          <v-chip
            label
            class="font-weight-medium secondary--text text--lighten-2"
          >
            {{ item.sku }}
          </v-chip>
        </template>

        <template v-slot:[`item.rank`]="{ item }">
          <div class="text-right">{{ item.rank }}</div>
        </template>

        <template v-slot:[`item.created_date`]="{ item }">
          {{ formatDateFullMonth(item.created_date) }}
        </template>

        <template v-slot:[`item.options`]="{ item }">
          <v-card-actions>
            <TooltipIcon
              icon="mdi-pencil"
              color="secondary lighten-2"
              label="Editar plano"
              class="mx-2"
              size="24"
              button
              @action="selectPlan(item)"
            />
          </v-card-actions>
        </template>
      </v-data-table>
    </v-card>

    <v-dialog
      v-model="addPlanDialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card tile color="grey lighten-3">
        <v-toolbar dark color="primary">
          <v-btn icon dark @click="resetEditAddDialog()">
            <v-icon v-text="'mdi-close'" />
          </v-btn>
          <v-toolbar-title v-text="editPlan ? 'Editar plano' : 'Criar plano'" />
          <v-spacer />
        </v-toolbar>

        <v-stepper v-model="addPlanSteps" class="py-0">
          <v-stepper-header
            class="d-flex justify-start py-2"
            style="max-height: 50px"
          >
            <v-stepper-step
              :complete="addPlanSteps > 1"
              step="1"
              class="ml-4 mr-6"
              :style="addPlanSteps > 1 ? 'cursor:pointer' : ''"
              @click="addPlanSteps > 1 ? (addPlanSteps = 1) : ''"
            >
              Informações sobre o {{ planOrServiceLabel }}
            </v-stepper-step>

            <v-stepper-step
              v-if="!is_service"
              :complete="addPlanSteps > 2"
              step="2"
              class="mr-6"
              :style="addPlanSteps === 3 ? 'cursor:pointer' : ''"
              @click="addPlanSteps === 3 ? (addPlanSteps = 2) : ''"
            >
              Selecionar produtos
            </v-stepper-step>

            <v-stepper-step
              :step="is_service ? 2 : 3"
              class="mr-6"
              @click="
                plan.name !== '' && products.length > 1
                  ? (addPlanSteps = 3)
                  : ''
              "
              :style="
                plan.name !== '' && products.length > 1 ? 'cursor:pointer' : ''
              "
            >
              Detalhes do plano
            </v-stepper-step>
          </v-stepper-header>

          <v-stepper-items class="grey lighten-3 elevation-0">
            <v-stepper-content step="1" color="white mx-8 my-4">
              <v-card
                color="white"
                class="mx-auto elevation-0 mt-8 grey--text text--darken-1"
                tile
                width="800"
              >
                <v-app-bar
                  outlined
                  dense
                  tile
                  class="ma-0 elevation-0 text-h6 font-weight-regular grey--text text--darken-2"
                >
                  Informações sobre o {{ planOrServiceLabel }}
                </v-app-bar>
                <div class="px-6 py-4">
                  <v-text-field v-model="plan.name" label="Nome*" required />
                  <v-textarea
                    v-if="is_service"
                    v-model="plan.description"
                    label="Descrição"
                    maxlength="250"
                    counter
                    no-resize
                  />
                  <v-text-field
                    v-else
                    v-model="plan.description"
                    label="Descrição"
                    maxlength="80"
                    counter
                  />
                  <span class="caption my-2">*campo obrigatório</span>
                </div>
              </v-card>

              <v-card
                color="grey lighten-3"
                class="mx-auto elevation-0"
                width="800"
              >
                <v-card-actions class="float-right px-0 mt-2">
                  <v-btn color="grey" text @click="resetEditAddDialog()">
                    Cancelar
                  </v-btn>
                  <v-btn
                    class="elevation-0 px-4"
                    color="primary"
                    @click="addPlanSteps = 2"
                    :disabled="!plan.name || plan.name.length < 5"
                  >
                    Continuar
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-stepper-content>

            <v-stepper-content v-if="!is_service" step="2">
              <v-card
                color="white"
                class="mx-auto elevation-0 mt-4 mb-1"
                tile
                width="1000"
              >
                <v-card-title class="text-subtitle-2" v-text="plan.name" />
              </v-card>

              <v-card
                color="white"
                class="mx-auto elevation-0 mt-4 pb-6 grey--text text--darken-1"
                tile
                width="1000"
              >
                <v-app-bar
                  outlined
                  dense
                  tile
                  class="ma-0 elevation-0 text-h6 font-weight-regular grey--text text--darken-2"
                >
                  Selecionar produtos
                </v-app-bar>
                <v-card outlined tile class="px-0 mx-4 my-8">
                  <v-card-title>
                    Produtos da Conecta
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn small icon class="mx-1" v-on="on" v-bind="attrs">
                          <v-icon
                            v-text="'mdi-help-circle-outline'"
                            size="16"
                          />
                        </v-btn>
                      </template>
                      <span
                        >Selecione dois ou mais produtos para continuar</span
                      >
                    </v-tooltip>
                  </v-card-title>
                  <v-divider />
                  <v-text-field
                    v-model="searchProduct"
                    placeholder="Pesquisar pelo nome do produto"
                    filled
                    rounded
                    dense
                    background-color="white"
                    clearable
                    class="mb-0 mx-0 mt-1"
                    style="height: 45px"
                  >
                    <template v-slot:prepend-inner>
                      <v-icon class="mr-2" v-text="'mdi-magnify'" />
                    </template>
                  </v-text-field>
                  <v-divider />
                  <v-row>
                    <v-col class="px-6">
                      <v-treeview
                        v-model="products"
                        :search="searchProduct"
                        class="body-2 row_pointer treeview-style"
                        v-if="allProductsToChoose"
                        :items="allProductsToChoose"
                        selectable
                        selected-color="secondary"
                        open-all
                      />
                    </v-col>
                  </v-row>
                </v-card>
              </v-card>

              <v-card
                color="grey lighten-3"
                class="mx-auto elevation-0"
                width="1000"
              >
                <v-card-actions class="d-flex px-0 mt-2">
                  <v-btn text @click="addPlanSteps = 1"> Voltar </v-btn>
                  <v-btn
                    text
                    color="grey"
                    class="ml-auto"
                    @click="resetEditAddDialog()"
                  >
                    Cancelar
                  </v-btn>
                  <v-btn
                    class="elevation-0 px-4"
                    color="primary"
                    @click="addPlanSteps = 3"
                    :disabled="products.length < 2 && !plan.is_basic"
                  >
                    Continuar
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-stepper-content>

            <v-stepper-content :step="is_service ? 2 : 3">
              <v-card class="mx-auto elevation-0 mt-4 mb-1" tile width="1000">
                <v-card-title class="text-subtitle-2">
                  {{ plan.name }}
                  <v-spacer />
                  <v-btn icon color="grey" class="grey--text">
                    <v-icon v-text="'medi-pencil'" color="grey" />
                  </v-btn>
                </v-card-title>
              </v-card>

              <v-card
                color="white"
                class="mx-auto elevation-0 mt-4 pb-6 grey--text text--darken-1"
                tile
                width="1000"
              >
                <v-app-bar
                  outlined
                  dense
                  tile
                  class="ma-0 elevation-0 text-h6 font-weight-regular grey--text text--darken-2"
                >
                  Detalhes do {{ planOrServiceLabel }}
                </v-app-bar>
                <v-card outlined class="px-0 mx-4 my-8">
                  <v-card-title
                    class="grey--text text--darken-1 text-subtitle-1 font-weight-medium py-3"
                  >
                    Disponibilidade
                  </v-card-title>
                  <v-divider />
                  <v-row class="d-flex align-center px-4 my-0">
                    <v-col
                      lg="5"
                      md="3"
                      sd="12"
                      class="py-0 my-0 text-subtitle-2 font-weight-regular"
                    >
                      Status
                    </v-col>
                    <v-col lg="7" md="7" sd="12" class="py-0 my-0">
                      <v-switch
                        inset
                        v-model="plan.is_active"
                        :label="`${is_service ? 'Serviço' : 'Plano'} ${
                          plan.is_active ? 'ativo' : 'desativado'
                        }`"
                      ></v-switch>
                    </v-col>
                  </v-row>
                  <v-row v-if="plan.sku" class="d-flex align-center px-4 my-5">
                    <v-col
                      lg="5"
                      md="3"
                      sd="12"
                      class="py-0 my-0 text-subtitle-2 font-weight-regular"
                    >
                      SKU
                    </v-col>
                    <v-col
                      lg="7"
                      md="7"
                      sd="12"
                      class="py-0 my-0 font-weight-regular"
                    >
                      <code>{{ plan.sku }}</code>
                    </v-col>
                  </v-row>

                  <v-row class="d-flex align-center px-4 my-2">
                    <v-col
                      lg="5"
                      md="3"
                      sd="12"
                      class="py-0 my-0 text-subtitle-2 font-weight-regular"
                    >
                      Classificação
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            color="blue darken-2"
                            dark
                            small
                            v-bind="attrs"
                            v-on="on"
                          >
                            mdi-help-circle-outline
                          </v-icon>
                        </template>
                        <span
                          >Determina a ordem de upgrade de planos para sugestões
                          automáticas do sistema.</span
                        >
                      </v-tooltip>
                    </v-col>

                    <v-col
                      lg="7"
                      md="7"
                      sd="12"
                      class="py-0 my-0 font-weight-regular"
                    >
                      <v-combobox
                        v-model="rank"
                        :items="ranks"
                        label="upgrade/downgrade"
                        hint="Faça um upgrade ou downgrade deste plano"
                        min="0"
                        max="40"
                        dense
                        type="number"
                        :rules="[rules.rank]"
                      >
                        <template v-slot:append>
                          <v-icon v-if="rank > plan.rank" color="green"
                            >mdi-arrow-up-bold-outline</v-icon
                          >
                          <v-icon v-else-if="rank < plan.rank" color="red"
                            >mdi-arrow-down-bold-outline</v-icon
                          >
                          <v-icon v-else>mdi-swap-vertical-bold</v-icon>
                        </template>
                      </v-combobox>
                    </v-col>
                  </v-row>
                  <v-row class="d-flex align-center px-4 my-0">
                    <v-col
                      lg="5"
                      md="3"
                      sd="12"
                      class="py-0 my-0 text-subtitle-2 font-weight-regular"
                    >
                      O cliente pode contratar este {{ planOrServiceLabel }}
                      manualmente
                    </v-col>
                    <v-col lg="7" md="7" sd="12" class="py-0 my-0">
                      <v-switch
                        inset
                        v-model="plan.is_visible"
                        :label="`${
                          plan.is_visible ? 'Visível' : 'Não é visível'
                        }`"
                      ></v-switch>
                    </v-col>
                  </v-row>
                  <v-row class="d-flex align-center px-4 my-0">
                    <v-col
                      lg="5"
                      md="3"
                      sd="12"
                      class="py-0 my-0 text-subtitle-2 font-weight-regular"
                    >
                      Defina a disponibilidade deste
                      {{ planOrServiceLabel }} para parceiros
                    </v-col>
                    <v-col lg="7" md="7" sd="12" class="py-0 my-0">
                      <v-switch
                        inset
                        v-model="plan.only_conecta_hires"
                        :label="`${
                          plan.only_conecta_hires
                            ? 'Disponível somente para conecta'
                            : 'Disponível para todos os parceiros'
                        }`"
                      ></v-switch>
                    </v-col>
                  </v-row>
                </v-card>

                <v-card outlined class="px-0 mx-4 my-8">
                  <v-card-title
                    class="grey--text text--darken-1 text-subtitle-1 font-weight-medium py-3"
                  >
                    {{
                      is_service
                        ? "Valor total do serviço"
                        : "Produtos selecionados"
                    }}
                  </v-card-title>
                  <v-divider v-if="!is_service" />
                  <v-row class="d-flex align-center px-4 my-0">
                    <v-col
                      v-if="!is_service"
                      lg="5"
                      md="3"
                      sd="12"
                      class="py-2 my-0 text-subtitle-2 d-flex flex-column justify-center"
                    >
                      <div
                        v-for="product of products"
                        :key="product"
                        class="font-weight-bold py-1"
                      >
                        {{ productNames[product] }}
                        <span class="font-weight-regular ml-1 caption"
                          >Peso: {{ weights[product] }}</span
                        >
                        <v-chip
                          v-if="['conecta_track'].includes(product)"
                          class="ml-1"
                          x-small
                          >BETA</v-chip
                        >
                      </div>
                    </v-col>
                  </v-row>
                  <v-divider />
                  <v-row class="px-4 my-0 py-0">
                    <v-col class="d-flex align-center py-0 my-0">
                      <span class="text-h6 font-weight-bold">Total - </span>
                      <vuetify-money
                        v-model="plan.price"
                        :placeholder="'4,99'"
                        :options="moneyOptions"
                        :valueWhenIsEmpty="'0'"
                        class="pl-4 mt-2 pr-1"
                        required
                        dense
                        style="width: 120px"
                      />
                      <span
                        class="text-body-2 font-italic grey--text text--darken-1"
                        >/usuário/mês</span
                      >
                    </v-col>
                  </v-row>
                </v-card>
              </v-card>

              <v-card
                color="grey lighten-3"
                class="mx-auto elevation-0"
                width="1000"
              >
                <v-card-actions class="d-flex px-0 mt-2">
                  <v-btn text @click="addPlanSteps = 2"> Voltar </v-btn>
                  <v-btn
                    text
                    color="grey"
                    class="ml-auto"
                    @click="resetEditAddDialog()"
                  >
                    Cancelar
                  </v-btn>
                  <v-btn
                    class="elevation-0 px-4"
                    color="primary"
                    :disabled="
                      (plan.name === '' && products.length < 2) ||
                      rank < 0 ||
                      rank > 40
                    "
                    @click="showConfirmPlan = true"
                  >
                    Concluir
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-card>
    </v-dialog>
    <SimpleDialog
      id="confirm_edit_create_plan"
      :title="
        editPlan
          ? `Confirmar alterações no ${planOrServiceLabel} '${plan.name}'`
          : `Confirmar criação do ${planOrServiceLabel} '${plan.name}'`
      "
      :show="showConfirmPlan"
      @close="resetEditAddDialog"
      @action="createOrEditPlan"
      :disabled-action="plan.name === '' && products.length < 2"
      width="650"
    >
      <template v-slot:body>
        <p class="font-weight-bold text-body-1">
          Detalhes do {{ planOrServiceLabel }}:
        </p>
        <v-row>
          <v-col
            class="font-weight-bold text-subtitle-2"
            lg="6"
            md="6"
            sm="4"
            v-text="'Nome '"
          />
          <v-col lg="6" md="6" sm="6" v-text="plan.name" />

          <v-col
            class="font-weight-bold text-subtitle-2"
            lg="6"
            md="6"
            sm="4"
            v-text="'Descrição'"
          />
          <v-col lg="6" md="6" sm="6" v-text="plan.description" />

          <v-col
            class="font-weight-bold text-subtitle-2"
            lg="6"
            md="6"
            sm="4"
            v-text="'Periodicidade'"
          />
          <v-col lg="6" md="6" sm="6" v-text="planOrServicePeriodicity" />

          <v-col
            class="font-weight-bold text-subtitle-2"
            lg="6"
            md="6"
            sm="4"
            v-text="'Status'"
          />
          <v-col
            lg="6"
            md="6"
            sm="6"
            v-text="plan.is_active ? 'Ativo' : 'Desativado'"
          />

          <v-col
            class="font-weight-bold text-subtitle-2"
            lg="6"
            md="6"
            sm="4"
            v-text="'Visível para clientes'"
          />
          <v-col
            v-if="!is_service"
            lg="6"
            md="6"
            sm="6"
            v-text="
              plan.is_visible
                ? 'Visível para todos'
                : 'Somente para revendedores'
            "
          />
          <v-col
            v-else
            lg="6"
            md="6"
            sm="6"
            v-text="'Somente para a conecta nuvem'"
          />

          <v-col
            class="font-weight-bold text-subtitle-2"
            lg="6"
            md="6"
            sm="4"
            v-text="'Disponibilidade para parceiros'"
          />

          <v-col
            v-if="!is_service"
            lg="6"
            md="6"
            sm="6"
            v-text="
              plan.only_conecta_hires
                ? 'Disponível somente para conecta'
                : 'Disponível para todos os parceiros'
            "
          />

          <v-col
            v-if="!is_service"
            class="font-weight-bold text-subtitle-2"
            lg="6"
            md="6"
            sm="4"
            v-text="'Produtos'"
          />
          <v-col v-if="!is_service" lg="6" md="6" sm="6">
            <div
              v-for="product of products"
              :key="product"
              class="d-flex align-center"
            >
              <span class="font-weight-regular">{{
                productNames[product]
              }}</span>
              <span class="ml-1 caption"> Peso: {{ weights[product] }}</span>
              <v-chip
                v-if="['conecta_track'].includes(product)"
                class="ml-1"
                x-small
                v-text="'BETA'"
              />
            </div>
          </v-col>

          <v-col
            class="font-weight-bold text-subtitle-2"
            lg="6"
            md="6"
            sm="4"
            v-text="'Preço'"
          />
          <v-col
            lg="6"
            md="6"
            sm="6"
            v-text="`${formatMoney(plan.price)}/usuário/mês`"
          />
        </v-row>
      </template>
    </SimpleDialog>

    <HeaderDialog
      id="plans-config-dialog"
      title="Configurações gerais"
      color="primarySuite"
      :show="showConfigDialog"
      :loading="loadingConfig"
      width="700"
      showCancel
      actionText="Confirmar"
      closeText="cancelar"
      button-class="text-none text-subtitle-1 font-weight-medium elevation-0"
      @close="closeConfigModal"
      @action="setConfig"
    >
      <template v-slot:body>
        <v-row :class="$vuetify.breakpoint.smAndUp ? '' : 'd-flex flex-column'">
          <v-col>
            <p class="mt-4">
              Configure o limite de valor máximo para pagamentos com cartão,
              determinando o ponto no qual o sistema disponibilizará a opção de
              pagamento por cartão aos clientes.
            </p>

            <div class="d-flex align-center">
              <span>Valor máximo para pagamento com cartão: </span>
              <vuetify-money
                v-model="maxValueAllowedForCardPayment"
                :placeholder="'0,00'"
                :options="moneyOptions"
                :valueWhenIsEmpty="'0'"
                class="pl-4 mt-2 pr-1"
                required
                dense
                style="width: 120px"
              />
            </div>
          </v-col>
        </v-row>
      </template>
    </HeaderDialog>

    <Snackbar
      :show="showSnackbar"
      :color="snackbarColor"
      :message="snackbarMessage"
      notShowCloseButton
      :timeout="timeoutSnackbar"
      bottom
      textStyle="font-weight-medium"
    />
  </v-container>
</template>
<script>
import { mapGetters, mapMutations } from "vuex";
import Logo from "@/components/base/Logo";
import { productNames } from "@/helpers/variables/translateString";
import { formatDateFullMonth, formatMoney } from "@/helpers/services/utils";
import ButtonMenu from "@/components/base/ButtonMenu";
import { paging, tableHeight } from "@/helpers/variables/tablesDefault";
import { getDatabase, ref, set, child, get } from "firebase/database";
import { successMessages } from "@/helpers/variables/snackbarMessages";

// TODO: otimizar essa página para compartilhar elementos com a página de serviços
export default {
  name: "Plans",
  components: { Logo, ButtonMenu },
  data() {
    return {
      tableHeight,
      showConfirmPlan: false,
      search: "",
      products: [],
      filters: {
        products: [],
      },
      filterOptions: [
        {
          title: "Produtos",
          key: "products",
          show: true,
          submenu: {
            title: "Produtos",
            options: [],
          },
        },
      ],
      isSuccessMessage: false,
      snackbar: {
        show: false,
        message: "",
      },
      moneyOptions: {
        locale: "pt-BR",
        prefix: "R$",
        suffix: "",
        precision: 2,
      },
      addPlanSteps: 1,
      addPlanDialog: false,
      editPlan: false,
      productNames,
      plan: {
        name: "",
        description: "",
        products: [],
        frequency: ["FLEX", "ANNUAL"],
        is_active: true,
        is_visible: false,
        only_conecta_hires: false,
        price: 0,
        rank: 0,
      },
      plans: [],
      headers: [
        {
          text: "Nome",
          value: "name",
        },
        {
          text: "Produtos",
          value: "products",
          sortable: false,
          align: "start",
        },
        {
          text: "Preço",
          value: "price",
          sortable: false,
          align: "end",
        },
        {
          text: "Status",
          value: "is_active",
        },
        {
          text: "Visibilidade",
          value: "is_visible",
        },
        {
          text: "Data de criação",
          value: "created_date",
          sortable: false,
        },
        {
          text: "Classificação",
          value: "rank",
          sortable: false,
        },
        {
          text: "SKU",
          value: "sku",
          width: 200,
          sortable: false,
        },
        {
          text: "",
          value: "options",
          sortable: false,
        },
      ],
      items: [
        {
          id: "",
          name: "Conecta Control",
          children: [],
        },
      ],
      ranks: [0, 10, 20, 30, 40],
      rank: 0,
      rules: {
        rank: (value) =>
          (value >= 0 && value <= 40) || "Valores devem estar entre 0 e 40.",
      },
      weights: {
        conecta_suite: 1,
        conecta_control: 6,
        conecta_sign: 2,
        conecta_track: 2,
        conecta_control_mobile: 1,
        conecta_ad_sync: 1,
        integracao_ponto_mais: 1,
        service: 1,
      },
      searchProduct: "",
      loadingTable: false,
      timeoutSnackbar: 5000,
      planReset: {
        name: "",
        description: "",
        products: [],
        frequency: ["FLEX", "ANNUAL"],
        is_visible: false,
        is_active: true,
        price: 0,
        rank: 0,
      },
      itemsPerPage: paging.perPage,
      itemsOptions: paging.All,
      is_service: false,

      showConfigDialog: false,
      loadingConfig: false,
      creditCardMininmunValue: null,
      maxValueAllowedForCardPayment: 0,
    };
  },

  computed: {
    ...mapGetters(["token"]),

    snackbarMessage() {
      return this.snackbar.message;
    },

    planOrServiceLabel() {
      return this.is_service ? "serviço" : "plano";
    },

    planOrServicePeriodicity() {
      return this.is_service ? "Único" : "Mensal, Anual";
    },

    snackbarColor() {
      return this.isSuccessMessage ? "success" : "error";
    },

    allProductsToChoose() {
      return this.items;
    },

    showSnackbar: {
      get() {
        return this.snackbar.show;
      },
      set(new_value) {
        this.snackbar.show = new_value;
      },
    },

    hasFiltersSelected() {
      return this.filters.products.length > 0;
    },

    allPlans() {
      if (this.hasFiltersSelected) {
        return this.plans
          .filter((plan) => {
            if (
              plan.products.find((product) =>
                this.filters.products.includes(productNames[product.name])
              )
            ) {
              return plan;
            }
          })
          .filter((item) => !item.is_service);
      }
      return this.plans.filter((item) => !item.is_service);
    },

    allFilters() {
      let total = [];

      let items = Object.entries(this.filters);

      items.forEach((item) => {
        let values = item[1];
        if (values.length > 0) {
          values.forEach((value) => {
            total.push({
              name: value,
              key: item[0],
            });
          });
        }
      });
      return total;
    },
  },

  watch: {
    "plan.is_active"() {
      if (this.plan.is_visible && !this.plan.is_active) {
        this.plan.is_visible = false;
      }
    },
    addPlanDialog() {
      if (!this.addPlanDialog) {
        this.addPlanSteps = 1;
        this.editPlan = false;
        this.plan = Object.assign({}, this.planReset);
        this.rank = 0;
        this.showConfirmPlan = false;
        this.products = [];
      }
    },
  },

  methods: {
    formatDateFullMonth,

    formatMoney,

    ...mapMutations(["setMaxValueAllowedForCardPayment", "setSnackBar"]),

    openConfigModal() {
      this.showConfigDialog = true;
      this.getMaximunPriceForCreditCard();
    },

    closeConfigModal() {
      this.showConfigDialog = false;
    },

    setConfig() {
      this.loadingConfig = true;
      const database = getDatabase();
      const value = parseFloat(this.maxValueAllowedForCardPayment);

      set(ref(database, "suite_config"), {
        maxValueAllowedForCardPayment: value,
      });

      this.setMaxValueAllowedForCardPayment(value);

      this.loadingConfig = false;

      this.setSnackBar({
        color: "success",
        message: successMessages.saves_changes,
        showCloseButton: true,
        show: true,
      });

      this.closeConfigModal();
    },

    getMaximunPriceForCreditCard() {
      if (this.maxValueAllowedForCardPayment) {
        return;
      }

      const dbRef = ref(getDatabase());
      get(child(dbRef, `suite_config/maxValueAllowedForCardPayment`))
        .then((snapshot) => {
          this.maxValueAllowedForCardPayment = snapshot.exists()
            ? snapshot.val()
            : 0;
        })
        .catch((error) => {
          console.error(error);
        });
    },

    loadSnackbarInfo(hasSucceeded = true) {
      if (hasSucceeded) {
        this.isSuccessMessage = true;
        this.snackbar.message = this.editPlan
          ? `O plano ${this.plan.name} foi atualizado`
          : `O plano ${this.plan.name} foi criado com sucesso`;
      } else {
        this.isSuccessMessage = false;
        this.snackbar.message = this.editPlan
          ? `ERRO: o plano ${this.plan.name} não pode ser atualizado.`
          : `ERRO: o plano ${this.plan.name} não pode ser criado.`;
      }
      this.showSnackbar = true;
    },

    createNewPlanOrService(is_service = false) {
      this.is_service = is_service;
      this.showSnackbar = false;
      this.products = [];
      this.plan = Object.assign({}, this.planReset);
      this.addPlanDialog = true;
    },

    clearAllFilters() {
      this.filters = {
        products: Array(),
      };
    },

    removeFilter(filter) {
      let index = this.filters[filter.key].indexOf(filter.name);
      this.filters[filter.key].splice(index, 1);
    },

    selectFilter(item) {
      if (!this.filters[item.type].includes(item.filter)) {
        this.filters[item.type].push(item.filter);
      }
    },

    activeOrDeactivePlan(plan) {
      this.plan = Object.assign({}, plan);
      this.changePlanStatus();
    },

    resetEditAddDialog() {
      this.addPlanDialog = false;
    },

    selectPlan(chosenPlan) {
      this.editPlan = true;
      this.plan = Object.assign({}, chosenPlan);
      this.rank = this.plan.rank;
      let products = this.plan.products.map((product) => product.name);
      this.products = products.slice();
      this.plan.frequency = ["FLEX", "ANNUAL"];
      this.addPlanDialog = true;
    },

    async changePlanStatus() {
      this.loadingTable = true;

      let url = process.env.VUE_APP_API_BASE_URL + "/plan";

      const config = {
        headers: { Authorization: this.token },
      };

      const payload = {
        is_active: !this.plan.is_active,
      };

      await this.$axios
        .put(url + `/${this.plan.key}`, payload, config)
        .then(() => {
          this.editPlan = true;
          this.loadSnackbarInfo();
          this.loadingTable = false;
          this.getAllPlans();
          this.resetEditAddDialog();
        })
        .catch((err) => {
          console.error("createOrEditPlan(): ", err);
          this.editPlan = true;
          this.loadSnackbarInfo(false);
          this.loadingTable = false;
          this.resetEditAddDialog();
        });
    },

    async createOrEditPlan() {
      this.loadingTable = true;

      let url = process.env.VUE_APP_API_BASE_URL + "/plan";

      const config = {
        headers: { Authorization: this.token },
      };

      if (this.editPlan) {
        this.plan.products = this.products.map((product) => {
          return {
            name: product,
            weight: this.weights[product],
          };
        });

        this.plan.price = parseFloat(this.plan.price);
        this.plan.rank = parseInt(this.rank);

        await this.$axios
          .put(url + `/${this.plan.key}`, this.plan, config)
          .then(() => {
            this.loadSnackbarInfo();
            this.loadingTable = false;
            this.getAllPlans();
            this.resetEditAddDialog();
          })
          .catch((err) => {
            console.error("createOrEditPlan(): ", err);
            this.loadSnackbarInfo(false);
            this.loadingTable = false;
            this.resetEditAddDialog();
          });
      } else {
        const payload = {
          name: this.plan.name,
          description: this.plan.description,
          price: parseFloat(this.plan.price),
          products: this.products.map((product) => {
            return {
              name: product,
              weight: this.weights[product],
            };
          }),
          is_visible: this.plan.is_visible,
          is_active: this.plan.is_active,
          only_conecta_hires: this.plan.only_conecta_hires,
          rank: parseInt(this.rank),
          is_service: this.is_service,
        };

        await this.$axios
          .post(url, payload, config)
          .then(() => {
            this.loadSnackbarInfo();
            this.loadingTable = false;
            this.getAllPlans();
            this.resetEditAddDialog();
          })
          .catch((err) => {
            console.error("createOrEditPlan(): ", err);
            this.loadSnackbarInfo(false);
            this.loadingTable = false;
            this.resetEditAddDialog();
          });
      }
    },

    async getAllProducts() {
      let url = process.env.VUE_APP_API_BASE_URL + "/plans/products";

      const config = {
        headers: { Authorization: this.token },
      };

      await this.$axios
        .get(url, config)
        .then(({ data }) => {
          this.filterOptions[0].submenu.options = data.map((item) => {
            return {
              title: productNames[item.name],
              name: item.name,
              show: true,
            };
          });
          data.forEach((product) => {
            this.weights[product.name] = product.weight;
            if (product.name === "conecta_control") {
              this.items[0].children.push({
                id: product.name,
                name: productNames[product.name],
              });
              this.items[0].children.push({
                id: "conecta_control_mobile",
                name: productNames["conecta_control_mobile"],
              });
              this.items[0].children.push({
                id: "conecta_ad_sync",
                name: productNames["conecta_ad_sync"],
              });
              this.items[0].children.push({
                id: "integracao_ponto_mais",
                name: productNames["integracao_ponto_mais"],
              });
            } else {
              if (
                ![
                  "conecta_ad_sync",
                  "integracao_ponto_mais",
                  "conecta_control_mobile",
                  "conecta_control",
                ].includes(product.name)
              ) {
                this.items.push({
                  id: product.name,
                  name: productNames[product.name],
                });
              }
            }
          });
        })
        .catch((err) => {
          console.error("getAllProducts(): ", err);
        });
    },

    // TODO: trazer apenas serviços nessa query e criar um arquivo no vuex para ele
    async getAllPlans() {
      this.loadingTable = true;

      let url = process.env.VUE_APP_API_BASE_URL + "/plan";

      const config = {
        headers: { Authorization: this.token },
      };

      await this.$axios
        .get(url, config)
        .then(({ data }) => {
          this.plans = data;
        })
        .catch((err) => {
          console.error("getAllPlans(): ", err);
        });

      this.loadingTable = false;
    },
  },

  async beforeMount() {
    await this.getAllPlans();
    await this.getAllProducts();
  },
};
</script>
<style scoped>
.v-stepper__step {
  padding: 0px;
}
.v-stepper {
  -webkit-box-shadow: 0px 0px 0px 0px rgb(0 0 0 / 20%),
    0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 0px 0px 0px rgb(0 0 0 / 12%) !important;
  box-shadow: 0px 0px 0px 0px rgb(0 0 0 / 20%), 0px 0px 0px 0px rgb(0 0 0 / 14%),
    0px 0px 0px 0px rgb(0 0 0 / 12%) !important;
}
</style>
